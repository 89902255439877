import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '@/views/Projects.vue'
import CarPi from '@/views/Carpi.vue'
import Resume from '@/views/Resume.vue'
import Dream from '@/views/DreamBattleLoop.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      title: 'Projects'
    }
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume,
    meta: {
      title: 'Resume'
    }
  },
  {
    path: '/projects/CarPi',
    name: 'CarPi',
    component: CarPi,
    meta: {
      title: 'CarPi'
    }
  },
  {
    path: '/projects/DreamBattleLoop',
    name: 'Dream Battle Loop',
    component: Dream,
    meta: {
      title: 'FIGHT TIME'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} | James Reese` : 'James Reese';
  next();
})

export default router
