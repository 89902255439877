import axios from 'axios';

const http = axios.create({
    baseURL: 'https://ec2-18-216-45-255.us-east-2.compute.amazonaws.com:8080/'
});

export default {
    getLastDay() {
        return http.get(`/recent`);
    },
    getRecentLogs() {
        return http.get(`/logs/recentday`);
    }
}
