<template>
    <md-content id="about-me-container" class="md-primary md-elevation-8">
        <div class="md-layout md-alignment-center-left">
            <div id="about-me" class="md-layout-item md-elevation-25">
                <h2 class="md-layout-item md-display-2 md-size-25">About Me</h2>
            </div>
        </div>
        <div class="md-layout">
            <md-content id="contact-container" class="md-layout-item md-list md-small-size-60 md-medium-size-30 md-size-15 md-elevation-20">
                <h3 class="md-display-1 md-list-item">Let's Talk!</h3>
                <span class="md-list-item md-accent">ReeseJ2197@Gmail.com</span>
            </md-content>
        </div>
    </md-content>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
@import "@/assets/CustomThemes.scss";
@import "~vue-material/dist/theme/engine";

#contact-container {
    background: #8A5F58;
    margin: 1%;
}

#about-me-container {
    height: 10%;
}

</style>
