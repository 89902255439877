<template>
    <div class="card">
        <h2 id="name" >{{project.name}}</h2>
        <p id="desc" >{{project.description}}</p>
    </div>
</template>

<script>
export default {
    props: ['project']
}
</script>

<style scoped>
    .card {
        border: 1px solid rgba(0, 0, 0, .6);
        /* background-color: rgba(0, 0, 0, .2); */
    }
</style>

<style lang="scss" scoped>
    .card {
        background-color: md-default-accent;
    }
</style>
