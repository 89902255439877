<template>
    <div id="project-page">
        <div id="project-list">
            <div class="project md-layout" v-for="project in projects" v-bind:key="project.name">
                <router-link class="project-link md-layout-item" :to="{ path: project.name}" append><project-card v-bind:project="project"/></router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard'

export default {
    components: { ProjectCard },
    data () {
        return {
            projects: [
                {
                    name: 'CarPi',
                    description: 'Project built to extract data from OBD-II (diagnostic) port on a vehicle, and upload extracted data to an AWS hosted database.  Website will display the data in different forms including simple lists and graphs.'
                },
                {
                  name: 'DreamBattleLoop',
                  description: 'Fight in an endless dream (or maybe nightmare?) using nothing but the roll of a die to determine your fate!  Good luck gladiator!'
                }
            ]
        }
    }
}
</script>

<style scoped>
    .project-link {
        text-decoration: none;
        color: inherit;
    }
</style>
