<template>
  <md-content>
    <md-field id="pname-field" v-if="!nameEntered">
      <label>Player Name</label>
      <md-input v-model="player.name" maxlength=50></md-input>
    </md-field>

  <div id="game-container" v-if="nameEntered">
    <h2 id="round">You've had this dream {{ currentLife }} time(s).</h2>
    <h2 id="current-roll">You're on roll {{ currentRoll }} out of {{ maxRolls }}</h2>

    <div id="characters" class="md-layout md-alignment-center-space-around">
      <div id="player" class="character-container">
        <h2 class="md-display-1">{{ player.name }}</h2>

        <div id="stamina">
          <h3 class="md-title">Stamine Level</h3>
          <p>{{ player.stamina }}</p>
        </div>
        <div id="panic">
          <h3 class="md-title">Panic Level</h3>
          <p>{{ player.panic }} / {{ maxPanic }}</p>
        </div>
      </div>

      <div id="enemy" class="character-container">
        <h2 class="md-display-1">{{ enemy.name }}</h2>

        <div id="panic">
          <h3 class="md-title">Health</h3>
          <p>{{ enemy.health }}</p>
        </div>
      </div>
    </div>

    <md-content v-if="gameMessage" id="game-message">
      <p>{{ gameMessage }}</p>
    </md-content>

    <md-button id="roll-button" class="md-raised md-primary" @click="roll" md-disabled=waiting>Roll the die!</md-button>

  </div>

  </md-content>
</template>

<script>
export default {
  data() {
    return {
      //  Character Data
      nameEntered: true,
      player: { //  Player info
        name: "Dreaming Gladiator the Nameless",
        stamina: 10,
        panic: 10
      },
      enemy: {  // Enemy info
        name: "Nameless Enemy of the Dream",
        health: 10
      },

      //  Game Data
      currentLife: 1,
      currentRoll: 1,
      wins: 0,

      maxRolls: 10,     //  Maximum rolls before a loss occurs
      maxPanic: 20,     //  Max panic before a loss occurs

      //  Begining vallues for enemy and player info
      startingPanic: 10,
      startingStamina: 10,
      startingHealth: 10,

      loseWait: 10,  //  Time to wait after a loss in seconds
      winWait: 2,    //  Time to wait after a win in secons
      waiting: false,

      gameMessage: "",
    }
  },
  methods: {
    roll() {
      //  Get a dice roll between numbers 1-6
      const diceRoll = Math.floor(Math.random() * 6) + 1;

      switch(diceRoll) {
        case 1: //  Increase player panic by 2
          this.gameMessage = "You got hit!  Ouchy!  Your panic increased by 2";
          this.player.panic += 2;
          break;
        case 2: //  Reduce player stamina by 1
          this.gameMessage = "As the enemy attacks you leap into the air dodging the attack!  Doing this flashy maneuver reduced your stamina by 1";
          this.player.stamina --;
          break;
        case 3: //  No stat changes
          this.gameMessage = "Holy sh- crap! You deflect their attack sending metal sparks flying from the two weapons hitting!";
          break;
        case 4: //  Increase player stamina by 1
          this.gameMessage = "Your initimidate your enemy with a hard stare; the extra time to rest restores your stamina by 1.";
          this.player.stamina ++;
          break;
        case 5: //  Reduce enemy health by 3 and player panic by 1
          this.gameMessage = "The enemy manages to dodge your strike, but suffers a glancing blow.  Their health falls by 3 points and your confidence goes up by 1.";
          this.enemy.health -= 3;
          this.player.panic --;
          break;
        case 6: //  Reduce enemy health by 5 and player panic by 3, increase stamina by 2
          this.gameMessage = "You land a massive hit to the enemy!!! The enemy stumbles back and loses 5 hp.  Your manage to catch your breath and your confidence goes up, increasing your stamina by 2 and decresing your panic by 3";
          this.enemy.health -= 5;
          this.player.stamina += 2;
          this.player.panic -= 3;
          break;
      }

      this.afterRoll();
    },
    afterRoll() {
      const gameStatus = this.determineWinOrLoss();

      if(gameStatus === 'win' || gameStatus === 'lose') {
        this.resetGame(gameStatus);
      }
      else {
        this.player.stamina --;
        this.player.panic ++;
        this.currentRoll ++;
      }
    },
    determineWinOrLoss() {

      if(this.enemy.health <= 0) {
        this.gameMessage = `Congratulations you won the fight!  Bask in the glory for ${this.winWait} seconds and then you'll be granted the honor of fighting again!`;
        return 'win';
      }
      else if(this.player.panic >= this.maxPanic) {
        this.gameMessage = `Bro calm down.  You lost your cool and your enemy took advantage.  Try again in ${this.loseWait} and do better next time.`;
        return 'lose';
      }
      else if(this.player.stamina <= 0) {
        this.gameMessage = ``;
        return 'lose';
      }
      else if(this.currentRoll >= this.maxRolls) {
        this.gameMessage = `You took too long and got WAY to worked up about it!  Your panic caused you to pass out and your enemy got an easy kill.  In ${this.loseWait} you'll have another chance.  Please try to remain calm next time :/`;
        return 'lose';
      }
    },
    resetGame(gameStatus) {
      if(gameStatus == 'win') {     //  If player won: increment wins by 1 and wait 2 seconds
        setTimeout(() => {
          this.wins ++;
          this.waiting = false;
        }, (this.winWait * 1000));
        this.waiting = true;
      }
      else {                        //  If player lost: reset player panic and player stamina
        setTimeout(() => {
          this.player.panic = this.startingPanic;
          this.player.stamina = this.startingStamina;
          this.waiting = false;
        }, (this.loseWait * 1000));
        this.waiting = true;
      }

                                    //  Reset enemy health, currentRoll and increment currentLife by 1
      this.enemy.health = this.startingHealth;
      this.currentRoll = 1;
      this.currentLife ++;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/CustomThemes.scss';

  #game-message {

  }
</style>
