<template>
    <div>
        <div id="about-me">
            <About-me/>
        </div>
        <Resume/>
    </div>
</template>

<script>
import Resume from '@/components/Resume.vue'
import AboutMe from "@/components/AboutMe.vue"

export default {
    components: {
        Resume,
        AboutMe
    }
}
</script>

<style scoped>
    #about-me {
        margin-bottom: 4%;
    }
</style>