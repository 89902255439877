<template>
    <div>
        <h1>Welcome to CarPi!</h1>
        <log-list id="recent-logs" :logs="recentLogs" />

        <md-dialog-alert
        :md-active.sync="popup" 
        md-content="API to access data is not currently deployed.  Sorry for the inconvenience."
        md-confirm-text="It's ok, I forgive you James!" />

        <h3 v-if="!popup">API to access data is not currently deployed.  Sorry for the inconvenience.</h3>
    </div>
</template>

<script>
import service from '@/services/CarpiServices.js'
import LogList from '@/components/LogList.vue'

export default {
    data () {
        return {
            recentLogs: [],
            popup: true,
        }
    },
    methods: {
    getRecentLogs() {
            service
            .getRecentLogs()
            .then(response => {
                if (response.status === 200) {
                    this.recentLogs = response.data
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    created() {
        this.getRecentLogs();
    },
    components: {
        LogList
    }
}
</script>
