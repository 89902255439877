<template>
  <dream-battle-loop/>
</template>

<script>
import DreamBattleLoop from '@/components/DreamBattleLoop'

export default {
  components: {
    DreamBattleLoop
  }
}
</script>
