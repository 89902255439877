<template>
    <div class="container">
        <ul>
            <li v-for="log in logs" :key="log.id">{{log.date}} {{log.time}} {{log.value}} {{log.dataType}}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['logs']
}
</script>

<style scoped>

</style>
