<template>
  <md-content id="app">
      <!-- <construction/> -->
      <div class="md-layout md-gutter md-alignment-top-left">

        <md-list id="nav" class="md-layout-item md-layout-nowrap md-xsmall-size-20 md-medium-size-10 md-size-5 md-dense md-primary">
          <md-list-item to="/" class="md-layout-item">
            <md-avatar class="md-avatar-icon md-medium md-accent md-elevation-4">
              <img src="@/assets/home.png" alt="home icon">
              <md-tooltip md-direction="top" md-delay="500">Home Page</md-tooltip>
            </md-avatar>
          </md-list-item>

          <md-list-item to="/projects" class="md-layout-item">          
            <md-avatar class="md-medium md-accent md-elevation-4">
              <img src="@/assets/projects.png" alt="lightbulb icon">
              <md-tooltip md-direction="top" md-delay="500">Projects Page</md-tooltip>
            </md-avatar>
          </md-list-item>

          <md-list-item to="/resume" class="md-layout-item">          
            <md-avatar class="md-medium md-accent md-elevation-4">
              <img src="@/assets/resume.png" alt="resume icon">
              <md-tooltip md-direction="top" md-delay="500">Resume Page</md-tooltip>
            </md-avatar>
          </md-list-item>
          
        </md-list>


        <!-- <md-content class="md-primary">PRIMARY</md-content>
        <md-content class="md-accent">ACCENT</md-content>
        <md-content class="md-light-shade">DARK-SHADE</md-content>
        <p class="md-accent">HELLO</p> -->
      <router-view class="md-layout-item"/>
      </div>
  </md-content>
</template>

<script>
// import Construction from '@/components/Construction.vue'

export default {
  name: 'App',
  components: {
    // Construction
  }
}
</script>

<style>
 #app {
   text-align: center;
 }
</style>

<style lang="scss">
@import "~vue-material/dist/theme/engine";
@import "@/assets/CustomThemes.scss";

#nav > .md-list-item {
  padding-top: 2rem;
  // padding-left: 10%;
  // width: 70px;
}
</style>
